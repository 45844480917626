<template>
    <div class="grid grid-cols-12 gap-4">
        <div class="col-span-3 bg-white rounded-lg shadow-lg h-max">
            <div class="p-5">
                <div>
                    <label class="text-gray-400">
                        服務商 手機
                        <el-input v-model="filterOpts.order_id" size="small" clearable> </el-input>
                    </label>
                </div>
                <div>
                    <label class="text-gray-400">
                        列帳月份
                        <el-select v-model="filterOpts.category_id" size="small" class="w-full" clearable>
                            <el-option v-for="(status, index) in payStatusOptions" :key="index" :label="status.label" :value="status.value">
                                {{ status.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>
                <div class="col-span-2 flex items-end mt-5">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="col-span-9 rounded-lg relative">
            <div>
                <LoadingComponent :isLoading="loading" />
                <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
                    <div class="w-[100%] grid grid-cols-12 gap-4">
                        <div class="col-span-5 flex flex-col items-center">
                            <div>總收益</div>
                            <div class="font-medium md:text-2xl text-base mt-2">$ 80,072</div>
                        </div>

                        <div class="col-span-5 flex flex-col items-center border-l border-gray-100">
                            <div>服務商數</div>
                            <div class="font-medium md:text-2xl text-base mt-2">5 位</div>
                        </div>

                        <div class="col-span-2 flex items-end">
                            <button class="black-btn-outline md:text-sm text-xs w-[100px] h-[40px] flex-1">返回列表</button>
                        </div>
                    </div>
                </div>
                <div class="overflow-x-auto bg-white">
                    <table class="myTable">
                        <TableHead ref="TableHead" :labels="tableLabels" @changeSort="changeSort" />
                        <tbody>
                            <tr class="text-center duration-200 transition">
                                <td>暱稱至多顯示十個字</td>
                                <td>2021 / 12 款項</td>
                                <td>$ 10,192</td>
                                <td>$ 10,192</td>
                                <td>$ 10,192</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="justify-center mt-3 flex mb-3">
                        <MyPagination
                            :paginationData="paginationData"
                            :currentPage="currentPage"
                            @onPageSizeChange="pageSizeChange"
                            @onCurrentPageChange="currentPageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// table Head
import TableHead from "@/components/table/TableHead.vue";
// init search Filter options
const defaultFilterOpts = {
    sort_by: "", // created_at or started_at or gross_price
    sort_type: "", // asc or desc
};
export default {
    name: "ProviderList",
    components: {
        LoadingComponent,
        MyPagination,
        TableHead,
    },
    props: {
        // 是否為服務商
        isProvider: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        // table 標籤
        tableLabels() {
            const tableLabels = [
                {
                    label: "暱稱",
                },
                {
                    label: "月結款項",
                },
                {
                    label: "訂單總額",
                },
                {
                    label: "服務商收益",
                },
                {
                    label: "介紹人收益",
                },
            ];
            return tableLabels;
        },
        // 統整 getlist api filters
        filterData() {
            const filter = {};
            for (const key in this.filterOpts) {
                if (this.filterOpts[key]) {
                    filter[key] = this.filterOpts[key];
                }
            }
            return {
                ...filter,
                role: this.isProvider ? 1 : 0,
                rated_only: 1,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 活動選項 options
        payStatusOptions() {
            const result = [
                {
                    label: "上架",
                    value: 1,
                },
                {
                    label: "未上架",
                    value: 0,
                },
            ];
            return result;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            id: "", // 服務商id
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
            total: {
                average: 0,
                rating_count: 0,
                score_count: {},
            },
        };
    },
    methods: {
        // 預設
        setDefault(val) {
            this.id = val.id;
            this.getList();
        },
        // 讀取 list api
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchDatingByUser(this.id, this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        // 搜尋
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        // 改變排序
        changeSort(val) {
            this.filterOpts.sort_by = val.sortBy;
            this.filterOpts.sort_type = val.orderBy;
            this.getList();
        },
        // 重置
        reset() {
            this.filterOpts = { ...defaultFilterOpts };
            this.$refs.TableHead.reset();
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        // 切頁相關
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
    },
    activated() {
        if (this.id) {
            this.getList();
        }
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}

::v-deep .el-message-box__message {
    word-wrap: break-word !important;
}
</style>
